import React from 'react'

import { PageLayout } from '@/components/PageLayout'
import { Head } from '@/components/head'
import { CompanyDataList } from '@/components/common/data-list'

const definitionList = [
  {
    term: '会社名',
    description: '株式会社Relicホールディングス (Relic Holdings Inc.)'
  },
  {
    term: '代表取締役CEO',
    description: '北嶋 貴朗'
  },
  {
    term: '所在地',
    description:
      '〒150-6019 東京都渋谷区恵比寿4丁目20番3号　恵比寿ガーデンプレイスタワー19F'
  },
  {
    term: '資本金',
    description: '5,000万円'
  },
  {
    term: '設立',
    description: '2021年9月'
  }
]

export default function Company(): JSX.Element {
  return (
    <>
      <Head title="会社情報 | 株式会社Relicホールディングス" url="company" />
      <PageLayout
        title="会社情報"
        subTitle="COMPANY"
        breadcrumbs="/COMPANY"
        breadcrumbsPath="/company"
      >
        <CompanyDataList definitionList={definitionList} />
      </PageLayout>
    </>
  )
}
